import React, { Component } from "react";
import { Link } from "gatsby";

import Layout from "../layouts/ja";
import SEO from "../components/seo";

export default class TermsAndConditionsPage extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const specific_date = new Date("2022-01-09");
    const current_date = new Date();
    const url = this.props.location.href;
    const sectionUrl = url?.split("#")[1] ?? "";
    //scroll to section if have the sectionURL
    if (sectionUrl) {
      const element = document.getElementById(sectionUrl);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (current_date.getTime() > specific_date.getTime()) {
      return (
        <Layout location={this.props.location}>
          <SEO
            title="規約と条件 "
            keyword="規約と条件"
            decription="規約と条件"
          />

          <div className="terms">
            <div className="container container--1000">
              <h1 className="text--center">利用規約</h1>
              <p className="p--sm">更新日 10月 2024年</p>
              <p className="p--sm">
                以下は、PT AYANA Hotel Manajemen（「当社」または「AYANA
                Hospitality」と呼ばれる）とアヤナリワードプログラムの会員間の契約上の合意を示す改訂後の利用規約です。これらの更新された利用規約は[月]
                [日]
                [年]（「施行日」と呼ばれる）から効力が発生し、以前のアヤナリワードプログラムの利用規約に取って代わります。施行日以降もアヤナリワードプログラムの会員として参加し続けることによって、以下に示す更新された利用規約を承諾したものとみなされます。
              </p>
              <p className="p--sm">
                アヤナリワードは、あなたの洗練された趣味にお応えして、アヤナでの特別な体験へのアクセスを提供するロイヤルティプログラムです。選ばれたお客様のために用意されたパーソナルな体験や、無料の朝食、レイトチェックアウト、優先的な部屋のアップグレードなど、特別な特典をご堪能いただけます。アヤナの伝説的なクラフトマンシップと洗練されたサービスに浸りながら、私たちの魅力的な目的地とのより深い繋がりを見つけてください。
              </p>
              <p className="p--sm" style={{ marginBottom: "24px" }}>
                以下の利用規約は、貴重な会員様に最高の体験を保証するためのものです。
              </p>
              <Link
                id="general-terms-conditions"
                href="#general-terms-conditions"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                }}>
                一般利用規約:
              </Link>
              <ol style={{ marginTop: "7.5px", marginBottom: "24px" }}>
                <li>
                  個人情報に変更があった場合、会員自身がアヤナリワード会員ポータルで会員情報を更新するか、アヤナリワード会員サービスに書面で通知する責任があります。
                </li>
                <li>
                  アヤナリワードは、規則、制限、プログラムの特典、リワードの交換、および特別オファーを予告なしに変更する権利を有します。アヤナリワードは、アヤナリワードプログラムをいつでも終了する権利を有します。
                </li>
                <li>
                  アヤナリワードは、アヤナリワードを通じて請求、引き換え、および/または享受された商品またはサービスの品質に関して、明示または黙示を問わず保証や表明を行わず、その責任（包括的損害賠償を含む）を明示的に否認します。法律により示唆されるすべての条件、保証またはその他の用語は、適用法および規制が許可する最大限の範囲で除外されます。会員がリワード証書の使用を譲渡した場合、この条項は譲受人にも適用されます。
                </li>
                <li>
                  参加ホテルおよび/またはアヤナリワードは、市場条件の変動を反映するために、必要に応じてアヤナリワードポイントの価値を調整する権利を有します。
                </li>
                <li>
                  アヤナリワードまたは報酬の使用に関する詐欺や乱用は、参加ホテルおよび/またはアヤナリワードの参加パートナーによって適切な管理および/または法的措置が講じられます。
                </li>
                <li>
                  このプログラムは、法律で禁止されている地域では無効です。
                </li>
                <li>
                  アヤナリワードロイヤルティプログラムは、参加ホテルが運営するプログラムです。
                </li>
                <li>
                  プログラム利用規約のすべての解釈はアヤナリワードの独自の裁量に委ねられます。紛争が生じた場合、会員はインドネシアの裁判所の専属管轄に従うことに同意します。
                </li>
              </ol>
              <Link
                id="glossary"
                href="#glossary"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                }}>
                1. 用語集
              </Link>
              <ol type="A" style={{ marginTop: "7.5px", marginBottom: "24px" }}>
                <li>
                  <span style={{ fontWeight: "700" }}>
                    アクティブステータス:{" "}
                  </span>
                  最後に記録された活動の日付から24ヶ月以内にウェブサイトのログインを行い、資格のある宿泊を完了し、資格のある支出を行うか、アヤナリワードポイントを利用した個人を指します。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>リワードポイント: </span>
                  参加施設での対象となる宿泊料金および/または資格のある支出を通じて獲得されたポイントです。これには、プロモーションオファーやレベルに応じたインセンティブを含みますがそれに限定されません。これらのポイントは、プログラムの利用規約に従い、空き状況に応じて特別な体験に引き換えることが可能です。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>カレンダー年: </span>
                  会員が現在の会員レベルに登録、アップグレード、更新、またはダウングレードされた日から、その年の1月1日から12月31日までの期間です。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>レベルポイント: </span>
                  会員が現在の会員レベルに登録、アップグレード、更新、またはダウングレードされた日から、その年の1月1日から12月31日までの期間です。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>適用宿泊数: </span>
                  次の高いレベルへの資格取得や現在のレベルの維持にカウントされるポイントです。プロモーションオファーや会員ステータスの一部として授与されるボーナスポイントはレベルポイントに含まれません。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>適用宿泊料金: </span>
                  https://www.ayana.com/、https://rewards.ayana.com/、https://www.delonixhotel.com/
                  または適用予約サイトを通じて予約できる参加ブランドのいかなる公表宿泊料金も含みます。参加ブランドは、独自の裁量で特定の宿泊料金を随時適用宿泊料金として指定する権利を有します。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>適用予約方法: </span>
                  適用予約方法には、ホテル直接予約、https://www.ayana.com/、https://rewards.ayana.com/、https://www.delonixhotel.com/を通じての予約が含まれます。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>適用追加費用: </span>
                  参加ブランドが管理する参加レストラン、スパ、またはレクリエーション施設での支出を指し、会員がホテルゲストであるか非ホテルゲストであるかに関わらず適用されます。参加ブランドは、独自の裁量で特定の取引を随時適用として指定する権利を有します。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>適用外予約方法: </span>
                  第三者プラットフォーム、オンラインサービスプロバイダー、オンライン旅行代理店、卸売業者、主催者またはプランナー、およびオフライン旅行代理店を通じての予約は適用されません。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>適用外宿泊料金: </span>
                  オンライン旅行代理店、オフライン旅行代理店、および卸売業者などの第三者サイトを通じて予約された部屋は適用されません。このカテゴリーには、グループ、結婚式、イベント＆会議、航空会社クルー、家族＆友人、無料宿泊、無料宿泊特典、マスターアカウントに請求された宿泊料金、手数料がかかる料金、および明示的に記載されていない他の特別料金が含まれます。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>ブランド: </span>
                  アヤナリワードにサービスを提供する参加レストラン、スパ、またはパートナー（サードパーティ）。
                </li>

                <li>
                  <span style={{ fontWeight: "700" }}>ポイントで支払い: </span>
                  アヤナリワードポイントを使用して適応可能な料金を予約できる機能を指します。
                </li>
                <li>
                  <span style={{ fontWeight: "700" }}>交換バウチャー: </span>
                  必要なポイントを使用して交換できる無料宿泊、食事、スパ、その他のカテゴリのクーポンを指します。
                </li>
              </ol>
              <Link
                id="membership-eligibility"
                href="#membership-eligibility"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                }}>
                2. 会員資格
              </Link>
              <ol style={{ marginTop: "7.5px", marginBottom: "24px" }}>
                <li>
                  すべてのゲストはアヤナリワード会員資格を得ることができます。アヤナリワードの会員資格は譲渡できません。会員は、複数のアカウントで会員資格を維持したり、ポイントを獲得してはなりません。
                </li>
                <li>
                  アヤナリワードポイントの発行対象になるためには、アヤナリワード会員はアクティブステータスを維持しなければなりません。
                </li>
                <li>
                  会員アクティブステータスを維持しなかった場合、会員資格が無効になり、積み立てられたポイントを期限に関係なく無効となります。
                </li>
                <li>
                  アヤナリワードは、理由を明示することなく会員資格を拒否または終了する権利を有し、提供された情報が不正確で不完全である場合は申請を拒否する権利を有します。
                </li>
              </ol>
              <Link
                id="membership-levels"
                href="#membership-levels"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                }}>
                3. 会員レベル
              </Link>
              <ol style={{ marginTop: "7.5px", marginBottom: "24px" }}>
                <li>
                  アヤナリワードは、シルバー、ゴールド、プラチナの3つの会員レベルを提供しており、シルバーが入門レベルとなっています。
                </li>
                <li>
                  以下の表は、会員レベルを維持またはアップグレードするために必要な有資格宿泊数またはレベルポイントを示しています:
                </li>
                <table>
                  <tr style={{ height: "25%" }}>
                    <th
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      レベル
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      適用宿泊数
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        verticalAlign: "middle",
                        textAlign: "center",
                        wordBreak: "break-all",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                      rowspan="4">
                      または
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      レベルポイント
                    </th>
                  </tr>
                  <tr style={{ height: "25%" }}>
                    <td
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid black",
                        textAlign: "center",
                      }}>
                      シルバー
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      該当なし
                    </td>
                    <td style={{ padding: "10px", textAlign: "center" }}>
                      該当なし
                    </td>
                  </tr>
                  <tr style={{ height: "25%" }}>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      ゴールド
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      20 | または
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      8,000
                    </td>
                  </tr>
                  <tr style={{ height: "25%" }}>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      プラチナ
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      50 | または
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      20,000
                    </td>
                  </tr>
                </table>
                <li>
                  会員は、最後のアップグレードまたはダウングレードの翌カレンダーイヤーの12月31日までに、必要なポイントまたは適用宿泊数を維持しなければなりません。これらの最低要件を満たせない場合は、会員レベルがダウングレードされます。ments
                  will result in a downgrade of membership level.
                </li>
                <li>
                  アヤナ会員レベルは、最後のアップグレード、ダウングレード、または維持の翌カレンダー年の12月31日まで有効です。
                </li>
                <li>
                  適用宿泊数またはレベルポイントは、レベルステータスを決定するためだけのものであり、これらの基準はアヤナリワードの独自の絶対的な裁量により随時変更されることがあります。
                </li>
                <li>
                  アヤナリワードは、会員資格のアップグレードおよび更新のための基準をいつでも予告なく変更する権利を有します。
                </li>
              </ol>
              <Link
                id="member-benefits"
                href="#member-benefits"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                }}>
                4.会員特典
              </Link>
              <h3>4.1 一般条件</h3>
              <ol style={{ marginBottom: "24px" }}>
                <li>
                  アヤナリワードゴールドまたはプラチナのステータスを獲得した会員は、ホテル滞在および非ホテル滞在に関連する特典を享受でき、詳細は以下の表よりご確認ください：
                </li>
                <table>
                  <tr>
                    <th
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      特典
                    </th>
                    <th
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      シルバー
                    </th>
                    <th
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      ゴールド
                    </th>
                    <th
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      プラチナ
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      会員限定の部屋割引
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        fontWeight: "700",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      適用
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      適用
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      適用
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      予約した同じ部屋クラス内のワンランク無料アップグレード
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      適用外
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      適用
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      適用
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      適用支出に対するボーナスポイント
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      適用外
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      25%
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        fontWeight: "700",
                        textAlign: "center",
                      }}>
                      50%
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      アーリーチェックイン
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      適用外
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      11:00
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      11:00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      レイトチェックアウト
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      適用外
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      16:00
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      18:00
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      １回の無料朝食
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      適用外
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                      }}>
                      適用外
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      2名
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      ウェルカムギフト
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        border: "1px solid black",
                      }}>
                      適用外
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      適用
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      適用
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      誕生日の際の無料アメニティ
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}>
                      適用
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      適用
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      適用
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      参加ブランドでの会員限定割引
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}>
                      適用
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      適用
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      適用
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                      }}>
                      ウェルネスプログラム特典
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}>
                      適用
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      適用
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        width: "25%",
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "700",
                      }}>
                      適用
                    </td>
                  </tr>
                </table>
                <li>会員の特典は譲渡できず、会員自身のみが請求できます。</li>
                <li>
                  アヤナリワードゴールド会員は、適用支出に対して25％のボーナスポイントを、プラチナ会員は50％のボーナスポイントを受け取ることできます。ただし、デロニクス
                  レジデンスでの取引は例外で、ゴールド会員はこれらの取引で10％のボーナスを、プラチナ会員は25％のボーナスを受け取ることができます。
                </li>
                <li>
                  アヤナリワード会員は、参加ブランドでの割引の特典があります。
                  <ol type="a">
                    <li>
                      https://rewards.ayana.com/
                      を通じて予約し、会員としてサインインすると、会員専用の割引を利用できます。
                    </li>
                    <li>
                      部屋を予約した後にサインアップした場合、または参加ブランドで部屋を予約する際にログインしなかった場合、会員専用の部屋割引を請求する資格がありません。
                    </li>
                  </ol>
                </li>
                <li>
                  アヤナリワード会員の特典は、空き状況および適応除外日での特典の利用は制限される可能性があります。
                </li>
                <li>
                  アヤナリワードは、会員への事前通知無しに、特典を変更または撤回する権利を有します。
                </li>
              </ol>
              <h3>4.2 ホテル滞在向け会員特典</h3>
              <ol>
                <li>
                  アヤナリワード会員に対するホテル滞在特典は、レジデンスに滞在している方や長期滞在（30連泊以上）を行っている方には適用されません。
                </li>
                <li>
                  ホテル滞在関連の会員特典を受けるには、会員は少なくとも1部屋を使用する必要があります。別の会員と部屋を共有している場合、代表者として予約に登録された会員のみが会員特典を受ける権利があります。会員が複数の部屋を予約した場合、すべての会員特典は会員が実際に使用している部屋にのみ適用されます。
                </li>
                <li>
                  同じ場所で連泊を予約していても、異なるホテルやタイプの部屋に滞在する場合、無料の朝食やウェルカムギフトなどのホテル滞在関連の特典は、メンバーの最初の滞在中のみ適用されます。チェックアウトしてすぐに再チェックインした場合も同様です。
                </li>
                <li>
                  ホテル滞在関連の会員特典には以下が含まれます：
                  <ol type="a">
                    <li>
                      同じ部屋クラスでの無料ワンランク部屋アップグレード
                      <ol type="i">
                        <li>
                          無料のワンランク部屋アップグレードは、1滞在につき会員1人に対して、1部屋のアップグレードに制限されます。
                        </li>
                        <li>
                          このアップグレードは同じ部屋カテゴリー内で有効であり、別の部屋カテゴリへのアップグレード（例：部屋からスイート、スイートからヴィラなど）には使用できません。
                        </li>
                        <li>
                          この特典は、レジデンス、複数ベッドルームの部屋、ヴィラを予約した会員には適用されません。
                        </li>
                        <li>
                          ワンランク上の客室への無料アップグレードは、会員の到着時に参加ホテルに空室があることが条件となります。
                        </li>
                      </ol>
                      <li>
                        アーリーチェックインおよび/またはレイトチェックアウト
                        <ol type="i">
                          <li>
                            会員は、到着時に参加ホテルの空き状況に応じて、アーリーチェックインおよび/またはレイトチェックアウトを受ける権利があります。
                          </li>
                          <li>
                            11:00のアーリーチェックインを希望する場合、少なくとも到着の2日前までにフライト情報や到着予定時刻を提供して申し込むことを推奨します。
                          </li>
                          <li>
                            レイトチェックアウトの要望は、到着時に受付で行うことを推奨します。
                          </li>
                        </ol>
                        <li>
                          誕生日滞在時の無料アメニティ <br />
                          会員は誕生日に滞在する場合、無料の誕生日アメニティを受ける権利があります。この無料のアメニティは参加ホテルによって異なる場合があります。
                        </li>
                        <li>
                          ウェルネスプログラム特典
                          <ol type="i">
                            <li>
                              参加ホテルに滞在中、会員はウェルネスプログラム特典の対象となります。この特典は、複数の会員が同じ部屋に滞在する場合、主たる部屋予約に登録された会員にのみ適用されます。
                            </li>
                            <li>
                              特典は参加ホテルによって異なる場合があり、場所に基づいて詳細が異なります。
                            </li>
                          </ol>
                        </li>
                        <li>
                          無料の朝食1回
                          <ol type="i">
                            <li>
                              無料朝食は、朝食なしで部屋を予約したアヤナリワードプラチナ会員に提供されます。こちらは、会員と同行する1名様に適用されます（大人か子供かは問いません）。会員は無料朝食特典を利用するにはホテルから提供されたバウチャーを提示する必要があります。紛失した場合、バウチャーは再発行されません。
                            </li>
                          </ol>
                        </li>
                        <li>
                          ウェルカムギフト
                          <ol type="i">
                            <li>
                              アヤナリワードゴールドおよびプラチナ会員には、滞在毎にウェルカムギフトが提供されます。提供される特定のギフトは参加ホテルによって異なり、場所に基づいて詳細が異なります。
                            </li>
                            <li>
                              ウェルカムギフトは、適用予約方法を通じてご滞在を予約された会員にのみ提供されます。
                            </li>
                            <li>
                              チェックアウト前に使用されなかったウェルカムギフトは放棄されたものとみなされます。
                            </li>
                            <li>
                              紛失した場合、バウチャーは再発行されません。
                            </li>
                          </ol>
                        </li>
                      </li>
                    </li>
                  </ol>
                </li>
              </ol>
              <h3>4.3 会員特典 - ホテルに滞在しない場合</h3>
              <ol type="1" style={{ marginBottom: "24px" }}>
                <li>
                  参加ブランドでの会員限定割引
                  <ol type="a">
                    <li>
                      会員は、アヤナリワード会員プロファイルを提示することで、参加ブランドでの専用割引を楽しむことができます。ただし、参加ブランドごとに特定の利用規約が適用される場合がありますのでご注意ください。
                    </li>
                    <li>
                      支払い時にアヤナリワード会員プロファイルを提出しない場合、参加ブランドでの専用割引は受けられません。これらの割引は、ホテルのフロントデスクでのチェックアウト時には適用できません。
                    </li>
                  </ol>
                </li>
              </ol>
              <Link
                id="points-earning"
                href="#points-earning"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                }}>
                5. ポイント獲得
              </Link>
              <h3>5.1 一般条件</h3>
              <ol style={{ marginBottom: "24px" }}>
                <li>
                  会員は、デロニクスホテル、デロニクスレジデンス、およびリバーサイド
                  ゴルフクラブを除く参加ブランドでの支出IDR10,000
                  毎に1ポイントのアヤナリワードポイントを獲得します。デロニクスホテル、デロニクスレジデンス、およびリバーサイド
                  ゴルフクラブでは、税金とサービス料を除く、適用の支出IDR15,000毎に1ポイントのアヤナリワードポイントを獲得します。参加ブランドには、アヤナミッドプラザ
                  ジャカルタ、アヤナ コモド ワエチチュビーチ、アヤナ セガラ
                  バリ、アヤナ ヴィラズ バリ、アヤナリゾート バリ、およびリンバ
                  by アヤナバリ が含まれます。
                </li>
                <li>
                  アヤナリワードポイントの獲得および報酬の交換は、適用されるすべての法律と規制の対象となります。
                </li>
                <li>ポイントは、最も近い整数ポイントに切り捨てられます。</li>
                <li>
                  税金、サービス料、チップ、およびその他の料金は、適用の付帯料金として分類されず、したがってアヤナリワードポイントを獲得する資格はありません。
                </li>
                <li>
                  積み立てられたポイントの有効期間は、最後のアクティブステータスの日付から24ヶ月です。
                </li>
                <li>
                  会員は、アヤナリワードメンバーアカウントに不足しているクレジットがあることに気づいた場合、チェックアウト日/取引日から60日以内にアヤナリワードメンバーシップサービスチームに通知する必要があります。この期間内にメンバーシップサービスチームに通知しない場合、関連するアヤナリワードポイントのクレジットが行われません。
                </li>
                <li>
                  会員は、チェックアウト/取引の7営業日後にポイントステータスを確認できます。欠落しているアヤナリワードポイントに関する請求は、info@ayanarewards.comのAYANA
                  Rewardsメンバーシップサービスチームに電子メールで提出するか、メンバーシップアカウントの「欠落ポイントフォーム」を通じて提出することができます。
                </li>
                <li>
                  アヤナリワードは、ポイントが誤ってアカウントにクレジットされた場合、会員のアカウント残高からポイントを差し引く権利を有します。
                </li>
              </ol>
              <h3>5.2 ポイント獲得 – ホテル＆リゾート</h3>
              <ol>
                <li>
                  アヤナリワードポイントは、適格な宿泊料金で滞在中に特定の基準を満たすメンバーのために用意されています。これらの条件には以下が含まれます：
                  <ol type="a">
                    <li>
                      メンバーは予約時に滞在ゲストとして登録されている必要があります。
                    </li>
                    <li>
                      アヤナリワードポイントは、同じ日に参加ホテルで予約された最大5部屋まで獲得できます。
                    </li>
                    <li>
                      5部屋すべてが会員と同じ会員番号またはメールアドレスで登録されている必要があります。
                    </li>
                  </ol>
                </li>
                <li>
                  アヤナリワード会員が部屋の予約者としてのみ行動し、参加ホテルに自身でチェックインせずに滞在しない場合、滞在中に発生した部屋および付帯料金に対するアヤナリワードポイントは獲得できません。
                </li>
                <li>
                  参加ホテルでは、部屋の料金に関係なく、適用外の予約方法を通じて予約した会員は、適用宿泊数またはポイントを獲得する資格がありません。ただし、会員が部屋に計上した適用追加料金に対するアヤナリワードポイントは引き続き獲得できます。
                </li>
                <li>
                  会員が適用追加料金を部屋に計上しない場合、参加ブランドのホテルスタッフに、追加料金からアヤナリワードポイントを獲得したいことを知らせる必要があります。
                </li>
                <li>
                  お客様がアヤナリワードの会員になり、アクティブステータスを維持した後に、アヤナリワードポイントを獲得する資格があります。ポイントはチェックアウト後7営業日以内に会員のアカウントにクレジットされます。
                </li>
                <li>
                  アヤナリワードポイント獲得資格のある料金：
                  <ol type="a">
                    <li>
                      アヤナミッドプラザジャカルタ
                      <ol type="i">
                        <li>
                          部屋料金にはアーリーチェックイン、レイトチェックアウト、追加の人物料金が含まれますが、デイユースは含まれていません。
                        </li>
                        <li>客室サービス（ミニバーを除く）</li>
                        <li>
                          ホテルが管理するレストラン（他社のレストランを除く、例：本膳、カド）
                        </li>
                        <li>特定のレクリエーション支出</li>
                        <li>スパ費用</li>
                      </ol>
                      <li>
                        デロニクスホテルカラワン
                        <ol type="i">
                          <li>
                            部屋料金にはアーリーチェックイン、レイトチェックアウト、追加の人物料金が含まれますが、デイユースは含まれていません。
                          </li>
                          <li>ルームサービス（ミニバーを除く）</li>
                          <li>
                            ホテルが運営するレストランでの支出（他社のレストランを除く、例：酒菜レストラン）
                          </li>
                          <li>特定のレクリエーション支出</li>
                          <li>ランドリーと交通費</li>
                        </ol>
                        <li>
                          アヤナバリ（アヤナ ヴィラズ バリ、アヤナ セガラ
                          バリ、アヤナ リゾートバリ、およびリンバ by
                          アヤナバリ）
                          <ol type="i">
                            <li>
                              部屋料金にはアーリーチェックイン、レイトチェックアウト、追加人数の料金が含まれますが、デイユースは含まれていません。
                            </li>
                            <li>ルームサービス（ミニバーを除く）</li>
                            <li>
                              ホテルが運営するレストランでの支出（他社のレストランを除く、例：本膳、炭膳）
                            </li>
                            <li>特定のレクリエーション支出</li>
                            <li>スパ費用</li>
                          </ol>
                          <li>
                            アヤ ナコモド ワエチチュ ビーチ
                            <ol type="i">
                              <li>
                                部屋料金にはアーリーチェックイン、レイトチェックアウト、追加の人物料金が含まれますが、デイユースは含まれていません。
                              </li>
                              <li>ルームサービス（ミニバーを除く）</li>
                              <li>
                                ホテルが管理するレストランでの支出（他社のレストランを除く、例：本膳）
                              </li>
                              <li>特定のレクリエーション支出</li>
                              <li>スパ費用</li>
                            </ol>
                          </li>
                          <li>
                            アヤナラコディア
                            <ol type="i">
                              <li>
                                プログラムパッケージ料金と個別の直接予約による夜間滞在チャーターは特別料金およびアルコールを含むその他の消費を除く
                              </li>
                            </ol>
                          </li>
                        </li>
                      </li>
                    </li>
                  </ol>
                </li>
              </ol>
              <h3>5.3 ポイント獲得 – アクティビティ</h3>
              <p className="p--sm">
                アヤナリワードポイントは、リバーサイドゴルフクラブでプレーし支払いを行ったプレイヤーに対してのみ付与されます。ゲストは、アヤナリワードプログラムに登録することで、ポイント累積の資格を得られ、取引後7営業日以内にアカウントにポイントが加算されます。リバーサイドゴルフクラブでのアヤナリワードポイントは、個人プレイヤーに対してのみ取得可能です。クラブで開催されるイベントはポイント獲得の対象外です。
              </p>
              <ol>
                <ol>
                  <li>
                    アヤナリワードポイント獲得資格のある料金：
                    <ol type="a">
                      <li>グリーンフィー</li>
                    </ol>
                  </li>
                  <li>
                    アヤナリワードポイント獲得対象外の料金：
                    <ol type="a">
                      <li>サービス料金</li>
                      <li>レストランのご利用</li>
                      <li>スパのご利用</li>
                      <li>リテール商品購入</li>
                      <li>リバーサイドゴルフクラブメンバーシップ購入</li>
                    </ol>
                  </li>
                </ol>
              </ol>
              <p className="p--sm" style={{ marginBottom: "24px" }}>
                リバーサイドゴルフクラブで年間メンバーシップを購入した会員は、アヤナリワードゴールドへの直接アップグレードの資格があります。長期メンバーシップを選択する人は、アヤナリワードプラチナムへの直接アップグレードを受け取ります。
              </p>
              <Link
                id="ayana-rewards-redemption"
                href="#ayana-rewards-redemption"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                }}>
                6. アヤナリワード交換
              </Link>
              <h3>6.1 一般条件</h3>
              <ol style={{ marginBottom: "24px" }}>
                <li>
                  特典券の保管は会員本人の責任です。特典券の再発行は行いません。
                </li>
                <li>
                  アヤナリワードに基づいて受け取った特典と報酬は課税対象となる場合があります。そのような税金は、当該特典または報酬の受益者である会員本人の責任です。
                </li>
              </ol>
              <h3>6.2 バウチャーの引き換え</h3>
              <ol>
                <li>
                  メンバーは、メンバーポータルの引き換えセクションで、rewards.ayana.comでアヤナリワードポイントを引き換えバウチャーに交換できます。会員のユーザーIDおよびパスワードに加えて、アヤナリワードは検証目的で他の個人情報を求める権利を留保します。
                </li>
                <li>
                  交換したバウチャーは、関連する製品またはサービスを利用する前に引き換えなければなりません。メンバーは、メールアドレスに送信された印刷または電子バウチャーを提示して、交換の証拠とする必要があります。
                </li>
                <li>
                  交換バウチャーが会員以外の人によって使用される場合、会員は事前に書面で電子メールを介してアヤナリワード会員カスタマーサービスに通知する必要があります。この情報を提供しない場合、ホテルが交換バウチャーの受け取りを拒否する可能性があります。
                </li>
                <li>
                  メンバーは、報酬を請求する前に、各報酬に概説された利用規約および手順を参照する責任があります。
                </li>
                <li>
                  交換したバウチャーを利用するには、メンバーはinfo@ayanarewards.comに電子メールを送信して、希望の利用日付を指定して事前予約を行う必要があります。ハイシーズン/ピークシーズン期間中は追加料金が適用される場合があります。
                </li>
                <li>
                  すべての交換済みバウチャーは払い戻し不可およびキャンセル不可です。メンバーのアヤナリワードポイントは交換時に差し引かれ、交換ポリシーに従います。
                </li>
                <li>
                  交換したバウチャーは、交換日から最大6ヶ月（180日）間有効です。ただし、特定の期間では、交換バウチャーの有効期間が6ヶ月未満である場合があります。
                </li>
                <li>
                  有効期限は、メンバーがバウチャーを利用できる最終日を示します。無料宿泊バウチャーの場合、これはチェックアウト日を指します。無料宿泊バウチャー以外の場合、メンバーがサービスを利用できる最終日を意味します。
                </li>
                <li>
                  未使用の交換バウチャーは、会員のアカウントにクレジットとして返却されたり、現金と交換されたり、延長されたりすることはできません。
                </li>
                <li>
                  アヤナリワードは、交換パートナーがプログラムから撤退したり、パートナーの報酬がキャンセルされたりした場合、その影響を受ける可能性がある交換に対して一切の責任を負いません。
                </li>
              </ol>
              <h3>6.3 ポイントで支払い</h3>
              <ol style={{ marginBottom: "24px" }}>
                <li>
                  会員は、ポイントで支払う機能を使用して、参加ブランドで部屋を予約するために獲得したアヤナリワードポイントを使用できます。
                </li>

                <li>
                  会員アカウントでポイントで支払い機能を有効にするには、最低500アヤナリワードポイントが必要です。
                </li>
                <li>
                  メンバーは、すべてのポイントまたはポイントと他の利用可能な支払い方法を組み合わせて使用するオプションがあります。
                </li>
                <li>
                  ポイントで支払いは、「アヤナリワードポイントを使用する資格がある」とマークされた部屋タイプと料金にのみ適用されます。
                </li>
                <li>
                  ポイントで支払いはhttps://www.ayana.com/、https://rewards.ayana.com/、またはhttps://www.delonixhotel.com/を通じて予約するメンバー専用に提供されます。この機能は、オフラインの適用予約方法からの予約には利用できません。
                </li>
                <li>
                  ポイントで支払いは、メンバーがこの機能を利用せずに予約した既存の予約には適用されません。
                </li>
                <li>
                  会員は滞在中に部屋料金や付帯料金からポイントを獲得でき、チェックアウト後7営業日以内にポイントが自動的に発行されます。
                </li>
                <li>
                  参加ホテルで明示的に許可されない限り、ポイントで支払い機能は部屋のオファーやプロモーションには適用されない場合があります。
                </li>
                <li>
                  ポイントで支払いを使用するメンバーは、予約内で概説されているように、各参加ブランドによって設定されたキャンセルポリシーの期限を遵守しなければなりません。違反するとペナルティが発生する場合があります。
                </li>
                <li>
                  支払いに使用されたポイントはポイントの形式で会員アカウントに返金されます。予約エンジンで利用可能な他の方法を使用して行われた支払いの場合、返金は予約時に選択した同じ支払い方法で発行されます。
                </li>
              </ol>
              <Link
                id="data-privacy"
                href="#data-privacy"
                style={{
                  scrollMarginTop: "120px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  scrollBehavior: "smooth",
                }}>
                7. データプライバシー
              </Link>
              <ol style={{ marginTop: "7.5px", marginBottom: "24px" }}>
                <li>
                  アヤナリワードは、会員管理と会員体験を向上させるために個人情報を収集します。これには、名前、性別、メールアドレス、国籍、誕生日、言語、電話番号、住所、居住国、会員レベル、ポイント活動、滞在履歴、アヤナリワードウェブサイトのログイン活動が含まれる場合があります。
                </li>
                <li>
                  収集された情報は、プログラム管理の効果的な管理と特典の提供を促進します。連絡先情報は、プログラムの更新および特別オファーの送信を可能にします。さらに、データは、ゲストの好みに基づいてアヤナでの体験をパーソナライズし、サービスを改善するのに役立ちます。
                </li>
                <li>
                  場合によってはアヤナはプログラムの運営を支援する信頼できる第三者サービスプロバイダーと個人情報を共有することがあります。これらのプロバイダーは、厳格なデータセキュリティプロトコルを順守しています。
                </li>
                <li>
                  ゲストには、プログラム関連の個人情報にアクセスし、修正し、または削除する権利があります。さらに、マーケティング通信のオプトアウトは常にオプションです。これらの権利を行使するための明確な指示がプログラム内で提供されています。
                </li>
              </ol>
            </div>
          </div>
        </Layout>
      );
    } else {
      return (
        <Layout location={this.props.location}>
          <SEO
            title="ご利用規約及び条件 "
            keyword="ご利用規約及び条件"
            decription="ご利用規約及び条件"
          />

          <div className="terms">
            <div className="container container--1000">
              <h1 className="text--center">ご利用規約及び条件</h1>
              <p className="p--sm">
                アヤナリワードはアヤナコモドリゾート ワエチチュ
                ビーチ、アヤナラコディア号、ザ ヴィラズ アット アヤナリゾート
                バリ、アヤナリゾート＆スパ バリ、リンバジンバラン
                バリbyアヤナ、アヤナミッドプラザ ジャカルタ、デロニクスホテル
                カラワンをご利用のゲストの皆様へ、日頃のご愛顧への感謝の気持ちを込めてご用意しております。
                下記にご案内する規則と規約は、アヤナリワード会員の保護のため設けられております。内容にご不明な点がございましたら、
                <a href="mailto:info@ayanarewards.com">info@ayanarewards.com</a>
                もしくは　
                <a href="https://ayanarewards.com">ayanarewards.com</a>
                よりお気軽に会員サービスまでご連絡ください。
              </p>
              <h3>会員資格</h3>
              <ol>
                <li>
                  全てのお客様にアヤナリワード会員にご入会いただけます。アヤナリワード会員の権利は、他人に譲渡することは出来ません。会員は、複数の口座で会員を維持したり、ポイントを獲得したりしてはいけません。
                </li>
                {/* <li>
                    <span style={{color: "rgb(34, 34, 34)", fontFamily: "arial, sans-serif", fontSize: "16px", whiteSpace: "preWrap", backgroundColor: "rgb(248, 249, 250)"}}>会員資格は2年以内に有効です。これらの年に活動がない場合、メンバーのアカウントは無効になり、累積されたポイントはすべて失効します。</span></li> */}
                <li>
                  ポイントが貯まると、よりステータスの高い会員になることができます。会員レベルは1年間有効です。年間5,000ポイントを集めるとゴールドにアップグレードされますが、ゴールドステータスを維持するためには年間1,500ポイントが必要となります。年間10,000ポイントを集めるとプラチナにアップグレードされ、プラチナステータスを維持するためには年間5,000ポイントが必要となります。上記のポイントが貯まらない場合は、会員レベルのダウングレードとなります。
                </li>
                <li>
                  アヤナリワードはお申込み時に必要かつ正確な情報の提示がなされなかった場合、通知なく会員の受付を拒否/停止する権利を有します。
                </li>
              </ol>
              <h3>ポイント獲得</h3>
              <ol>
                <li>
                  会員の方は、加盟ブランドの対象となる料金を10,000ルピアのご利用ごとにアヤナリワード1ポイントを獲得できます。デロニックス
                  カラワンホテル アンド
                  レジデンスでは、15,000ルピアのご利用ごとにアヤナリワード1ポイントを獲得できます。
                  ポイントは、税金およびサービス料加算前の金額を対象とし、小数点以下のポイントは切り捨てとなります。
                </li>
                <li>
                  ポイントは、対象料金でチェックイン、宿泊、および宿泊料金の支払いを受けた会員にのみ対象となります。
                  （1）各部屋がメンバーによって支払われ、すべての料金がチェックアウト時に決済された場合、（2）メンバーが一部屋を占有する場合、メンバーは参加施設で同じ滞在日数で5部屋までポイントを獲得できます。
                </li>
                <li>
                  アヤナ
                  リワードポイントの有効期限は発行日から10年間（120ヶ月）です
                </li>
                <li>
                  すべてのホテルでは、ご利用料金に関わらず、第三者サイト（GDSを除く）よりホテルの予約をされた場合には、宿泊料はポイントの対象とはなりません。その際、客室料金以外にご利用になられた項目に関しては、ポイントの対象となります。
                </li>
                <li>
                  <span
                    style={{
                      color: "rgb(34, 34, 34)",
                      fontFamily: "arial, sans-serif",
                      fontSize: "16px",
                      whiteSpace: "pre-wrap",
                      backgroundColor: "rgb(248, 249, 250)",
                    }}>
                    ゲストは、会員になった後にポイントを獲得する資格があります。
                  </span>
                  ポイントは、チェックアウト後
                  最大7営業日以内に会員様のアカウントに加算されます。
                </li>
                <li>
                  ポイント対象項目： ・アヤナミッドプラザ ジャカルタ
                  Oポイント対象の客室価格
                  ほとんどの客室料金に対してポイントを獲得できます。対象外の料金は、社員料金、コミッション対象料金、ホールセラー料金、無料宿泊ほか、マスターアカウント精算のもの、クルーメンバー料金、キャッシュ＆ポイント料金、グループ料金となります。対象外の客室料金でご宿泊の場合でも、客室料金以外の項目の料金についてはポイントの対象となります。各ホテルは、ポイント対象外の料金については独自に表示する責任があります。
                  O飲食料金 、除外するHonZen or KaDo。Oメンバー以外の飲食料金
                  <ul>
                    <li>
                      Oノーショー（無連絡での不泊）、アーリーデパーチャー（予約期間を短縮してのチェックアウト）、キャンセル時に生じた料金。
                    </li>
                    <li>
                      デロニクスホテル カラワン
                      <br />
                      Oご利用料金すべてがポイント対象 (excluding Sakana
                      Restaurant charges)
                    </li>
                    <li>
                      アヤナリゾート＆スパ バリ、ザ ヴィラズ アット
                      アヤナリゾート バリ、リンバジンバラン バリbyアヤナ&nbsp;
                      <br />
                      Oホテルへ直接予約された客室料金
                    </li>
                    <li>
                      アヤナ ラコディア号&nbsp;
                      <br />
                      直接お申込みのプログラムパッケージおよび宿泊チャーターに限り適用されます。特別料金や、アルコールを含む追加利用分には適用されません。
                    </li>
                  </ul>
                  <br />
                  **サービス料、税金ほか、諸雑費はアヤナリワードのポイント対象外です。
                </li>
                <li>
                  ポイント状況についてメンバーはアヤナリワードのポータルサイト-
                  ayanarewards.com
                  -　にログインし、リワードポイント残高や状況を確認できます。またinfo@ayanarewards.comよりアヤナリワード会員サービスマネージャーまでEメールにて確認することができます。
                </li>
                <li>
                  万一アヤナリワード会員アカウントにポイントが加算されていない場合、ご滞在のチェックアウト日より30日以内に、アヤナリワード会員サービスマネージャーまでご連絡ください。期限日までにご連絡がない場合、アヤナリワードポイントのご提供は出来かねますことご了承ください。会員様は、チェックアウト後より7営業日後にポイントを確認できます。アヤナリワードポイントが未反映である場合はEメール　ayanarewards@midplaza.comよりアヤナリワード会員サービスまでご連絡ください。
                </li>
                <li>
                  <span
                    style={{
                      color: "rgb(29, 28, 29)",
                      fontFamily: "Slack-Lato, appleLogo, sans-serif",
                      fontSize: "15px",
                      fontVariantLigatures: "common-ligatures",
                      backgroundColor: "rgb(255, 255, 255)",
                    }}>
                    アヤナリワードは、メンバーのアカウントへ誤ってポイントが加算された場合、その分のポイントを残高より差し引く権利を有しています。
                  </span>
                </li>
              </ol>
              <h3>リワードの請求・引き換え</h3>
              <ol>
                <li>
                  会員はポータルサイトayanarewards.comのリワード/引き換えセクションで、アヤナリワードのポイントをリワードと交換できます。その際メンバーのユーザーIDとパスワードに加え、アヤナリワードでは個人情報について確認する権利を有します。
                </li>
                <li>
                  各リワードには最低リワードポイントが設けられています。詳細についてはウェブサイトのリワードチャートをご覧ください。
                </li>
                <li>
                  報酬を請求する前に、各報酬に記載されている利用規約を常に参照することは会員の義務です。
                </li>
                <li>
                  夜間滞在の特典については、ご希望の滞在日を記載したメールをinfo@ayanarewards.comに送信して事前予約が必要です。ハイシーズン中は追加料金がかかる場合があります。
                </li>
                <li>
                  すべての特典は返金不可およびキャンセル不可です。会員のポイントは引き換え時に差し引かれ、引き換えポリシーに従います。
                </li>
                <li>
                  特典交換日から最大6ヶ月間有効です。未使用の報酬は、会員の口座への入金または現金との交換のために返金することはできません。
                </li>
                <li>
                  アヤナリワードは、参加パートナーが行うプログラムの停止や取り消しなどについて責任を負うものではありません。
                </li>
              </ol>
              <h3>一般規則</h3>
              <ol>
                <li>
                  アヤナリワード会員E-カードはアヤナリワードにおいてのみ有効です。カードのご利用に際してはここに記載する一般規則及び規定の元で特典やサービスをご提供します。
                </li>
                <li>
                  個人情報に変更があった場合、会員はご自身でアヤナリワード会員のポータルサイトにてその旨ご変更いただくか、アヤナリワード会員サービスまで書面でご連絡ください。
                </li>
                <li>
                  リワード認定書の保管は会員個人の責任によります。リワードカードの認定書の代替品はご用意しておりません。
                </li>
                <li>
                  アヤナリワード会員サービスオフィスでは、入力エラーなどによるメンバー明細やアヤナリワードポイントの残高などを変更する権利を有します。
                </li>
                <li>
                  アヤナリワードは規則及び規約、プログラム特典内容、特別オファーなどを予告なく変更する権利を有します。アヤナリワードはアヤナリワードプログラムをいつでも終了する権利を有します。
                </li>
                <li>
                  アヤナリワードポイントの獲得やリワードご利用については、法規制に準じます。
                </li>
                <li>
                  アヤナリワードでご用意する特典やリワードには税金が加算されます。特典やリワードをご利用の際に発生する税金についてはメンバーご個人の責任となりますこと、ご了承ください。
                </li>
                <li>
                  アヤナリワードは、リワードの特典や交換した商品及びサービスの品質、内容、全てにおいて責任（結果として生じた損害を含む）を負うものではなく、保証は一切行いません。法律によって暗示された全ての条件、保証またはその他の条項は、適用される法律や規則の許す限り除外されます。会員がリワード証書の利用を譲渡した場合、この条項は譲渡されたメンバーにも同様に適用されます。
                </li>
                <li>
                  参加ホテル及び/またはアヤナリワードは、市場の動向に合わせてアヤナリワードのポイント価値を変更する権利を有します。
                </li>
                <li>
                  アヤナリワードもしくはリワードのご利用に際して、悪用もしくは乱用が発生した場合、参加ホテル及び/又はアヤナリワード参加パートナーは適正な法的手段を講じます。
                </li>
                <li>このプログラムは違法行為において無効とされます。</li>
                <li>
                  アヤナリワードロイヤルティープログラムは、参加ホテルが運営するプログラムです。サービス提供者はメンバー明細へアクセスする場合がありますこと、ご了承ください。
                </li>
                <li>
                  プログラムの規則及び規約についてはアヤナリワード単独の裁量によるものです。万一紛争が生じた場合、会員はインドネシアの裁判所の管轄権に従うことに同意するものとします。
                </li>
              </ol>
            </div>
          </div>
        </Layout>
      );
    }
  }
}
